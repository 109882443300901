/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import { Component, components as configComponents } from '@app/components/config';
import { CategoryType, categoriesList } from '@app/constants/categoriesList';
import { TableDataType } from '@app/constants/enums/tableDataType';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { ClientBranchModel } from '@app/domain/interfaces/client_branch/clientBranchModel';
import { GroupModel } from '@app/domain/interfaces/client_branch/groupModel';
import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';
import { ConfigAlertModel } from '@app/domain/interfaces/configAlertModel';
import { DeviceModel } from '@app/domain/interfaces/device/deviceModel';
import { ProfileModel } from '@app/domain/interfaces/profile/profileModel';
import { UserModel } from '@app/domain/interfaces/user/userModel';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { setAlertTextPredicate, setFilter } from '@app/store/slices/headerSlice';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import * as S from './HeaderSearch.styles';

export interface CategoryComponents {
  category: CategoryType;
  components: Component[];
}

export const HeaderSearch: React.FC = () => {
  const { tableData, tableDataType } = useAppSelector((state) => state.header);
  const dispatch = useAppDispatch();
  const { mobileOnly, isTablet } = useResponsive();

  const { pathname } = useLocation();

  const [query, setQuery] = useState('');
  const [components] = useState<Component[]>(configComponents);
  const [searchValue, setSearchValue] = useState<string>('');

  const [isModalVisible, setModalVisible] = useState(false);
  const [isOverlayVisible, setOverlayVisible] = useState(false);

  const sortedResults = query
    ? categoriesList.reduce((acc, current) => {
        const searchResults = components.filter(
          (component) =>
            component.categories.includes(current.name) &&
            component.keywords.some((keyword) => keyword.includes(query)),
        );

        return searchResults.length > 0 ? acc.concat({ category: current.name, components: searchResults }) : acc;
      }, [] as CategoryComponents[])
    : null;

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchValue(e.currentTarget.value);

    if (tableDataType === TableDataType.Client) {
      handleClientFilter(e);
    }

    if (tableDataType === TableDataType.Alert) {
      handleAlertFilterClean(e);
    }

    if (tableDataType === TableDataType.Device) {
      handleDeviceFilter(e);
    }

    if (tableDataType === TableDataType.ConfigAlert) {
      handleConfigAlertFilter(e);
    }

    if (tableDataType === TableDataType.ConcreteMixer) {
      handleConcreteMixerFilter(e);
    }
    if (tableDataType === TableDataType.User) {
      handleUserFilter(e);
    }
    if (tableDataType === TableDataType.ClientBranch) {
      handleClientBranchFilter(e);
    }
    if (tableDataType === TableDataType.ClientGroup) {
      handleGroupFilter(e);
    }
    if (tableDataType === TableDataType.Profile) {
      handleProfileFilter(e);
    }
  };

  useEffect(() => {
    setSearchValue('');

    if (tableDataType !== TableDataType.Alert) {
      handleAlertFilter('');
    }
  }, [tableDataType]);

  useEffect(() => {
    if (tableDataType !== TableDataType.Alert) {
      setSearchValue('');
    }
  }, [tableData]);

  const handleClientFilter = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.currentTarget.value.length > 0 && tableData.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();

      const newTableData = _.filter(tableData, function (o: ClientModel) {
        return o.cnpj.includes(inputValue) || o.razaoSocial.toLowerCase().includes(inputValue);
      });

      dispatch(setFilter({ tableDataFiltered: newTableData }));
    } else {
      dispatch(setFilter({ tableDataFiltered: tableData }));
    }
  };

  const handleProfileFilter = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.currentTarget.value.length > 0 && tableData.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();

      const newTableData = _.filter(tableData, function (o: ProfileModel) {
        return o.descricao.toLowerCase().includes(inputValue);
      });

      dispatch(setFilter({ tableDataFiltered: newTableData }));
    } else {
      dispatch(setFilter({ tableDataFiltered: tableData }));
    }
  };

  const handleAlertFilterClean = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.currentTarget.value.length === 0) {
      dispatch(setAlertTextPredicate(null));
    }
  };

  const handleAlertFilterKeyUp = (event: React.KeyboardEvent) => {
    event.preventDefault();
    if (event.key === 'Enter') {
      // dispatch(setAlertTextPredicate(event.currentTarget.getAttribute('values')));
    }
  };

  const handleAlertFilter = (event: string) => {
    if (event.length > 0) {
      const inputValue = event.toLowerCase();

      dispatch(setAlertTextPredicate(inputValue));
    } else {
      dispatch(setAlertTextPredicate(null));
    }
  };

  const handleDeviceFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toUpperCase();

      const newTableData = _.filter(tableData, function (o: DeviceModel) {
        return (
          o.id?.toString().toUpperCase().includes(inputValue) ||
          o.imei?.toUpperCase().includes(inputValue) ||
          o.deviceId?.toUpperCase().includes(inputValue)
        );
      });
      dispatch(setFilter({ tableDataFiltered: newTableData }));
    } else {
      dispatch(setFilter({ tableDataFiltered: tableData }));
    }
  };

  const handleConfigAlertFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();

      const newTableData = _.filter(tableData, function (o: ConfigAlertModel) {
        return (
          o.chaveAlerta?.toString().includes(inputValue) ||
          o.titulo?.toLowerCase().includes(inputValue) ||
          o.categoria?.toLowerCase().includes(inputValue)
        );
      });

      dispatch(setFilter({ tableDataFiltered: newTableData }));
    } else {
      dispatch(setFilter({ tableDataFiltered: tableData }));
    }
  };

  const handleConcreteMixerFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();

      const newTableData = _.filter(tableData, function (o: ConcreteMixerModel) {
        return (
          o.numeroBT?.toLowerCase().includes(inputValue) ||
          o.placaCaminhao?.toLowerCase().includes(inputValue) ||
          o.modeloCaminhao.toLowerCase().includes(inputValue) ||
          o.cliente.toLowerCase().includes(inputValue)
        );
      });

      dispatch(setFilter({ tableDataFiltered: newTableData }));
    } else {
      dispatch(setFilter({ tableDataFiltered: tableData }));
    }
  };

  const handleUserFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();

      const newTableData = _.filter(tableData, function (o: UserModel) {
        return o.username?.toLowerCase().includes(inputValue) || o.email?.toLowerCase().includes(inputValue);
      });

      dispatch(setFilter({ tableDataFiltered: newTableData }));
    } else {
      dispatch(setFilter({ tableDataFiltered: tableData }));
    }
  };

  const handleGroupFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();

      const newTableData = _.filter(tableData, function (o: GroupModel) {
        return o.nome?.toLowerCase().includes(inputValue) || o.id?.toString().toLowerCase().includes(inputValue);
      });

      dispatch(setFilter({ tableDataFiltered: newTableData }));
    } else {
      dispatch(setFilter({ tableDataFiltered: tableData }));
    }
  };

  const handleClientBranchFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();

      const newTableData = _.filter(tableData, function (o: ClientBranchModel) {
        return (
          o.nome?.toLowerCase().includes(inputValue) ||
          o.agrupamentos?.find((group) => group.nomeGrupo.toLowerCase().includes(inputValue)) ||
          o.id?.toString().toLowerCase().includes(inputValue)
        );
      });

      dispatch(setFilter({ tableDataFiltered: newTableData }));
    } else {
      dispatch(setFilter({ tableDataFiltered: tableData }));
    }
  };

  useEffect(() => {
    setModalVisible(false);
    setOverlayVisible(false);
  }, [pathname]);

  return (
    <>
      {mobileOnly && (
        <>
          <Button
            type={isModalVisible ? 'ghost' : 'text'}
            icon={<S.SearchIcon onClick={() => setModalVisible(true)} />}
          />
          <S.SearchModal
            open={isModalVisible}
            closable={false}
            footer={null}
            onCancel={() => setModalVisible(false)}
            destroyOnClose
          >
            <BaseFormInputItem label="">
              <SearchInput
                value={searchValue}
                placeholder={
                  tableDataType === TableDataType.Alert
                    ? 'Clique no botão de pesquisa ou tecle Enter para buscar'
                    : 'Digite o termo a ser pesquisado'
                }
                onChange={handleOnChange}
                onSearch={TableDataType.Alert ? handleAlertFilter : undefined}
                onKeyUp={handleAlertFilterKeyUp}
              />
            </BaseFormInputItem>
          </S.SearchModal>
        </>
      )}

      {isTablet && (
        <BaseFormInputItem label="">
          <SearchInput
            value={searchValue}
            placeholder={
              tableDataType === TableDataType.Alert
                ? 'Clique no botão de pesquisa ou tecle Enter para buscar'
                : 'Digite o termo a ser pesquisado'
            }
            onChange={handleOnChange}
            onSearch={TableDataType.Alert ? handleAlertFilter : undefined}
            onKeyUp={handleAlertFilterKeyUp}
          />
        </BaseFormInputItem>
      )}
    </>
  );
};
