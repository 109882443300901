import React from 'react';
import { Container } from './Dashboard.styles';
import { IDashboard } from '@app/constants/interfaces/Dashboard/IDashboard';
import { HeaderTable } from '../header/components/HeaderTable/HeaderTable';
import * as S from './Dashboard.styles';

const Dashboard: React.FC<IDashboard> = ({
  buttonText,
  title,
  filter,
  tags,
  icon,
  table,
  handleButtonClick,
  handleSearchOnChange,
  placeholderTextSearch,
  valueSearch,
  exportToExcel,
  exportToPdf,
}) => {
  return (
    <Container>
      <S.Header>
        <HeaderTable
          buttonText={buttonText}
          title={title}
          filter={filter}
          tags={tags}
          icon={icon}
          valueSearch={valueSearch}
          placeholderTextSearch={placeholderTextSearch}
          handleButtonClick={handleButtonClick}
          handleSearchOnChange={handleSearchOnChange}
          exportToExcel={exportToExcel}
          exportToPdf={exportToPdf}
        />
      </S.Header>
      {table}
    </Container>
  );
};

export default Dashboard;
