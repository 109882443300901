export const BORDER_RADIUS = '7px';

export const BASE_COLORS = {
  white: '#FFFFFF',
  black: '#030306',
  green: '#117E2F',
  orange: '#FAAD14',
  gray: '#545454',
  lightgrey: '#CAD2EC',
  lightgreen: '#66C380',
  blue: '#383E5B',
  skyblue: '#1E2B53',
  red: '#F74E57',
};

export const LAYOUT = {
  mobile: {
    paddingVertical: '0.75rem',
    paddingHorizontal: '1rem',
    headerHeight: '4.25rem',
    headerPadding: '1rem',
  },
  desktop: {
    paddingVertical: '0.4rem',
    paddingHorizontal: '2rem',
    headerHeight: '5.625rem',
  },
};

export const FONT_FAMILY = {
  main: 'Mulish',
};

export const FONT_SIZE = {
  xxs: '0.75rem',
  xs: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.875rem',
  xxl: '2.375rem',
  xxxl: '2.875rem',
  xxxxl: '3.5rem',
};

export const FONT_WEIGHT = {
  regular: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
};

export const BREAKPOINTS = {
  xs: 360,
  sm: 568,
  md: 921,
  lg: 992,
  xl: 1280,
  xxl: 1920,
} as const;

const getMedia = <T extends number>(breakpoint: T): string => `(min-width: ${breakpoint}px)`;

export const media = {
  xs: getMedia(BREAKPOINTS.xs),
  sm: getMedia(BREAKPOINTS.sm),
  md: getMedia(BREAKPOINTS.md),
  lg: getMedia(BREAKPOINTS.lg),
  xl: getMedia(BREAKPOINTS.xl),
  xxl: getMedia(BREAKPOINTS.xxl),
};
