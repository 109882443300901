import DropdownTable from '@app/components/dropdown/DropdownTable';
import { Progress, Row } from 'antd';
import { ReactComponent as DownIcon } from '@app/assets/icons/downIcon.svg';
import { ReactComponent as ClockIcon } from '@app/assets/icons/clockIcon.svg';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Button } from '@app/components/common/buttons/Button/Button';
import { HeaderFullscreen } from '@app/components/header/components/HeaderFullscreen/HeaderFullscreen';
import Clock from '@app/components/common/Clock/Clock';
import * as S from './HeaderMonitoring.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useDispatch } from 'react-redux';
import { setHeaderBranchSelected } from '@app/store/slices/monitoringSlice';
import { DividerT } from '@app/components/common/divider/Divider';
import { DividerTitle } from '@app/components/common/divider/Divider.styles';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IconCaminhaoBetoneiraEsquerda, IconHome } from '@app/assets/slump-icons';
import { useNavigate } from 'react-router-dom';
import { HeaderMonitoringModel } from '@app/domain/interfaces/monitoring/headerMonitoringModel';
import { BranchModel } from '@app/domain/interfaces/monitoring/branchMonitoringModel';
import { hasAccessByRole } from '@app/controllers/accessController';

interface HeaderMonitoringProps {
  responsiveColumnsLayout: boolean;
}
interface HeaderMonitoringProps {
  forceCollapse: boolean;
  setForceCollapse: (isCollapsed: boolean) => void;
  setHiddenSider: Dispatch<SetStateAction<boolean>>;
  hiddenSider: boolean;
}

const HeaderMonitoring: React.FC<HeaderMonitoringProps> = ({
  forceCollapse,
  setForceCollapse,
  responsiveColumnsLayout,
  setHiddenSider,
  hiddenSider,
}) => {
  const dispatch = useDispatch();
  const handlerClickFullScreen = () => {
    setForceCollapse(!!forceCollapse);
    setHiddenSider(!hiddenSider);
  };
  const navigate = useNavigate();
  const headerInfoMonitoring = useAppSelector((state) => state.monitoring.headerInfoMonitoring);
  const headerBranchsMonitoring = useAppSelector((state) => state.monitoring.branchsMonitoring);
  const headerBranchSelected = useAppSelector((state) => state.monitoring.branchMonitoringSelected);
  const [header, setHeader] = useState<HeaderMonitoringModel>({} as HeaderMonitoringModel);

  const handlerBranchChange = (payload: BranchModel) => dispatch(setHeaderBranchSelected(payload));

  useEffect(() => {
    setHeader(headerInfoMonitoring);
  }, [headerInfoMonitoring]);

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        style={{ background: 'white', height: '5rem', padding: '0.5rem 2.5rem 0rem 1rem' }}
      >
        <Row>
          <S.DropdownHeader>
            <DropdownTable
              buttonText={!headerBranchSelected.filial ? 'Selecione a filial' : headerBranchSelected.filial}
              iconD={<DownIcon width={12} />}
              trigger={['click']}
              placement="bottomRight"
              overlay={
                <Menu>
                  {headerBranchsMonitoring.map((regional) => (
                    <MenuItem key={regional.idRegional}>
                      <Button type="text">
                        <DropdownTable
                          buttonText={regional.regional}
                          iconD={<DownIcon width={12} />}
                          trigger={['hover']}
                          placement="bottomRight"
                          overlay={
                            <Menu>
                              {regional.filiais.map((branch) => (
                                <MenuItem key={branch.idFilial} onClick={() => handlerBranchChange(branch)}>
                                  <Button type="text">{branch.filial}</Button>
                                </MenuItem>
                              ))}
                            </Menu>
                          }
                        ></DropdownTable>
                      </Button>
                    </MenuItem>
                  ))}
                </Menu>
              }
            ></DropdownTable>
          </S.DropdownHeader>
        </Row>
        <S.Column xl={12} xxl={15}>
          <Row align="middle" justify="center" gutter={[{ xxl: 6 }, { xxl: 9 }]}>
            <DividerT
              title={`${
                header?.aguaCentral ? Number.parseFloat(header.aguaCentral)?.toFixed(1)?.toString() : '-'
              } L/m³`}
              subtitle="Água Central"
              isDivider={true}
            />
            <DividerT title={`${header.inspecao} min`} subtitle="Inspeção" isDivider={false} />
            <DividerT title={`${header.produtividade} m³/h`} subtitle="Produtividade" isDivider={false} />
          </Row>
        </S.Column>
        <S.ConfigColumn $responsiveColumnsLayout={responsiveColumnsLayout}>
          <Row align="middle" justify="end" gutter={[{ xxl: 8 }, { xxl: 4 }]} style={{}}>
            <ClockIcon style={{ marginRight: '0.925rem' }} />
            <DividerTitle>
              <Clock />
            </DividerTitle>
            {hasAccessByRole('central_monitoring') && (
              <DividerT
                icon={
                  <div
                    onClick={() => navigate('/acompanhamento')}
                    style={{ color: '#263668', fontSize: '1.75rem', cursor: 'pointer' }}
                  >
                    <IconHome />
                  </div>
                }
              />
            )}
            {hasAccessByRole('mixer_monitoring') && (
              <DividerT
                icon={
                  <div
                    onClick={() => navigate('/monitoramento/betoneiras')}
                    style={{ color: '#263668', fontSize: '1.75rem', cursor: 'pointer' }}
                  >
                    <IconCaminhaoBetoneiraEsquerda />
                  </div>
                }
              />
            )}

            <DividerT
              icon={
                <div onClick={handlerClickFullScreen}>
                  <HeaderFullscreen />
                </div>
              }
            />
          </Row>
        </S.ConfigColumn>
      </Row>
      <Row style={{ padding: '0rem 2rem 0rem 1rem' }}>
        {/*<S.Progress percent={header.barraProgresso} showInfo={false} strokeColor="#159D3B">*/}
        {/*  <S.ProgressInnerText>Quero queroooooo</S.ProgressInnerText>*/}
        {/*</S.Progress>*/}
        <S.ProgressContainer style={{ width: header.barraProgresso + '%' }}>
          <Progress percent={100} showInfo={false} strokeColor="#159D3B" strokeWidth={16} />
          <S.ProgressInnerText color="white">
            {`${
              header.volumeExpedido
                ? Number(header?.volumeExpedido ?? 0)
                    .toFixed(1)
                    .toString()
                : '-'
            } M³`}
          </S.ProgressInnerText>
        </S.ProgressContainer>
        {header.barraProgresso < 100 && (
          <S.ProgressContainer style={{ width: 100 - header.barraProgresso + '%' }}>
            <Progress percent={0} showInfo={false} strokeColor="#159D3B" strokeWidth={16} />
            <S.ProgressInnerText color="black">
              {`${
                header.volumeTotal
                  ? Number(header?.volumeTotal ?? 0)
                      .toFixed(1)
                      .toString()
                  : '-'
              } M³`}
            </S.ProgressInnerText>
          </S.ProgressContainer>
        )}
      </Row>
    </>
  );
};

export default HeaderMonitoring;
