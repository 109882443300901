import { CloseCircleOutlined } from '@ant-design/icons';
import { IconMore } from '@app/assets/slump-icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { DrawerSuccess } from '@app/components/common/Drawer/DrawerSuccess/DrawerSuccess';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Modal } from '@app/components/common/Modal/Modal';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import { Table } from '@app/components/common/Table/Table';
import Dashboard from '@app/components/dashboard/Dashboard';
import DropdownTable from '@app/components/dropdown/DropdownTable';
import { hasAccessByPermission } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { ClientBranchModel } from '@app/domain/interfaces/client_branch/clientBranchModel';
import { GroupBranchModel } from '@app/domain/interfaces/client_branch/groupBranchModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import IClientBranchService, { ClientBranchService } from '@app/services/clientBranchService';
import { readUserFilter } from '@app/services/localStorage.service';
import { setHeader } from '@app/store/slices/headerSlice';
import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { genericExportToExcel } from '@app/utils/exportToExcel';

const clientBranchService: IClientBranchService = new ClientBranchService();

const ClientBranchDashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMounted } = useMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [drawerSuccessOpen, setDrawerSuccessOpen] = useState(false);
  const [modalDeleteClientBranchVisible, setModalDeleteClientBranchVisible] = useState(false);
  const [branches, setBranches] = useState<ClientBranchModel[]>();
  const [branchesFiltered, setBranchesFiltered] = useState<ClientBranchModel[]>();
  const [client, setClient] = useState<ClientModel>({} as ClientModel);
  const [clientBranch, setClientBranch] = useState<ClientBranchModel>({} as ClientBranchModel);

  const handlerCreateClick = () => {
    navigate('/gestao/filiais/create');
  };
  const handlerEditBranchClick = (branch: ClientBranchModel) => {
    navigate(`/gestao/filiais/edit/${branch.id}`);
  };
  const handlerDeleteBranchClick = (branch: ClientBranchModel) => {
    setClientBranch(branch);
    setModalDeleteClientBranchVisible(true);
  };
  const handlerDeleteBranch = () => {
    if (!clientBranch?.id) {
      notificationController.error({ message: 'Erro ao selecionar a filial' });
      return;
    }

    setModalLoading(true);

    clientBranchService
      .delete(clientBranch.id.toString())
      .then(() => {
        if (client?.id) {
          fetchData();
        }
        setModalLoading(false);
        setModalDeleteClientBranchVisible(false);
        notificationController.success({ message: 'Filial excluída com sucesso' });
      })
      .catch((error) => {
        notificationController.error(error);
        setModalLoading(false);
      });
  };
  const handleBranchesFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setBranchesFiltered(
        branches?.filter(
          (item) =>
            item.nome?.toLowerCase().includes(inputValue) ||
            item.logradouro?.toLowerCase().includes(inputValue) ||
            item.cep?.toLowerCase().includes(inputValue) ||
            item.agrupamentos?.find((group) => group.nomeGrupo.toLowerCase().includes(inputValue)),
        ),
      );
    } else {
      setBranchesFiltered(branches);
    }
  };
  const columns = (): ColumnsType<ClientBranchModel> => [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      sorter: (a: ClientBranchModel, b: ClientBranchModel) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      showSorterTooltip: false,
    },
    {
      title: 'Grupos',
      dataIndex: 'agrupamentos',
      showSorterTooltip: false,
      render: (agrupamentos: GroupBranchModel[]) =>
        agrupamentos.map((group, index) => {
          if (index == 0) {
            return <span key={group.id}>{group.nomeGrupo}</span>;
          } else if (agrupamentos.length == index + 1) {
            return <span key={group.id}>{` e ${group.nomeGrupo}`}</span>;
          } else {
            return <span key={group.id}>{`, ${group.nomeGrupo}`}</span>;
          }
        }),
    },
    {
      title: 'Endereço',
      dataIndex: 'logradouro',
      showSorterTooltip: false,
      render: (_, branch) =>
        ` 
        ${branch.logradouro ? `${branch.logradouro},` : ''}
        ${branch.numero ? `${branch.numero},` : ''}
        ${branch.complemento ? `${branch.complemento},` : ''}
        ${branch.bairro ? `${branch.bairro},` : ''}
        ${branch.cidade ? `${branch.cidade}-` : ''}
        ${branch.estado ? `${branch.estado}.` : ''}
        ${branch.cep ? `${branch.cep}` : ''}
      `,
    },
    {
      title: '',
      dataIndex: 'id',
      showSorterTooltip: false,
      render: (_, row) => {
        const canEdit = hasAccessByPermission('branch', 'U');
        const canDelete = hasAccessByPermission('branch', 'D');
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {(canEdit || canDelete) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ color: '#545454' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    {canEdit && (
                      <MenuItem>
                        <Button type="text" onClick={() => handlerEditBranchClick(row)}>
                          Editar
                        </Button>
                      </MenuItem>
                    )}
                    {canDelete && (
                      <MenuItem>
                        <Button type="text" onClick={() => handlerDeleteBranchClick(row)}>
                          Deletar
                        </Button>
                      </MenuItem>
                    )}
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  const handleExportToExcel = () => {
    genericExportToExcel('Filiais', columns(), branchesFiltered ?? []);
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const resBranches = await clientBranchService.getArray(``);

      setBranches(resBranches);
      setBranchesFiltered(resBranches);
    } catch (error) {
      notificationController.error({
        message: 'Erro ao buscar as filiais',
      });
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  useEffect(() => {
    const filter = readUserFilter();

    if (filter.client?.id) {
      fetchData();
      setClient(filter.client);
    }

    if (isMounted.current) {
      dispatch(
        setHeader({
          title: 'Filiais',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!modalDeleteClientBranchVisible) {
      setClientBranch({} as ClientBranchModel);
    }
  }, [modalDeleteClientBranchVisible]);

  return (
    <>
      <PageTitle>Filiais</PageTitle>
      <Spinner spinning={isLoading}>
        <Dashboard
          title={`Filiais   ${client?.razaoSocial ? `- ${client?.razaoSocial}` : ''}`}
          buttonText={hasAccessByPermission('branch', 'C') ? 'Cadastrar nova filial' : ''}
          handleButtonClick={handlerCreateClick}
          table={<Table bordered={true} columns={columns()} dataSource={branchesFiltered} loading={isLoading} />}
          placeholderTextSearch="Pesquisar por Nome ou Endereço"
          handleSearchOnChange={handleBranchesFilter}
          exportToExcel={handleExportToExcel}
        />
      </Spinner>
      <DrawerSuccess
        text="Concreteira salva com sucesso!"
        title="Concreteira"
        placement="right"
        onClose={() => setDrawerSuccessOpen(false)}
        open={drawerSuccessOpen}
        style={{ overflowX: 'hidden' }}
      />
      <Modal
        title="Deletar filial"
        open={modalDeleteClientBranchVisible}
        onOk={handlerDeleteBranch}
        onCancel={() => setModalDeleteClientBranchVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <CloseCircleOutlined size={20} style={{ color: '#C41B24' }} />
          </Col>
          <Col span={22}>Tem certeza que deseja excluir esta filial?</Col>
        </Row>
        <SpinnerSlump spinning={modalLoading}></SpinnerSlump>
      </Modal>
    </>
  );
};

export default ClientBranchDashboard;
