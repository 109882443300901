import { CloseCircleOutlined } from '@ant-design/icons';
import { IconMore } from '@app/assets/slump-icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Modal } from '@app/components/common/Modal/Modal';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import { Tooltip } from '@app/components/common/Tooltip/Tooltip';
import Dashboard from '@app/components/dashboard/Dashboard';
import DropdownTable from '@app/components/dropdown/DropdownTable';
import { hasAccessByPermission } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import IConcreteMixerService, { ConcreteMixerService } from '@app/services/concreteMixerService';
import { readUserFilter } from '@app/services/localStorage.service';
import { setHeader } from '@app/store/slices/headerSlice';
import { Col, Row, Tag } from 'antd';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SendCommandModal from '../../../components/common/SendCommandModal/SendCommandModal';

const concreteMixerService: IConcreteMixerService = new ConcreteMixerService();

const ConcreteMixerDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isMounted } = useMounted();
  const [mixers, setMixers] = useState<ConcreteMixerModel[]>();
  const [mixersFiltered, setMixersFiltered] = useState<ConcreteMixerModel[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalSendCommandVisible, setModalSendCommandVisible] = useState(false);
  const [idConcreteMixer, setIdConcreteMixer] = useState(0);
  const [client, setClient] = useState<ClientModel>({} as ClientModel);
  const [concreteMixer, setConcreteMixer] = useState<ConcreteMixerModel>({
    cliente: '',
    createdAt: new Date(),
    estadoBetoneira: 0,
    idCliente: 0,
    modeloCaminhao: '',
    numeroBT: '',
    placaCaminhao: '',
    qtdParafusos: 0,
    raioFlange: 0,
  });
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');

  const columns: ColumnsType<ConcreteMixerModel> = [
    {
      title: 'N° Frota',
      dataIndex: 'numeroBT',
      showSorterTooltip: false,
      render: (id: number, concreteMixer: ConcreteMixerModel) => {
        return (
          <Tooltip placement="topLeft" title={concreteMixer.deviceId ? concreteMixer.deviceId : ''}>
            <span>{concreteMixer.numeroBT}</span>
          </Tooltip>
        );
      },
      sorter: (a, b) => (a.numeroBT && b.numeroBT ? a.numeroBT.localeCompare(b.numeroBT) : 0),
      ellipsis: true,
    },
    {
      title: 'Placa',
      dataIndex: 'placaCaminhao',
      showSorterTooltip: false,
      sorter: (a, b) => (a.placaCaminhao && b.placaCaminhao ? a.placaCaminhao.localeCompare(b.placaCaminhao) : 0),
      ellipsis: true,
    },
    {
      title: 'Modelo do caminhão',
      dataIndex: 'modeloCaminhao',
      showSorterTooltip: false,
      sorter: (a, b) => (a.modeloCaminhao && b.modeloCaminhao ? a.modeloCaminhao.localeCompare(b.modeloCaminhao) : 0),
      ellipsis: true,
    },
    {
      title: 'Concreteira',
      dataIndex: 'cliente',
      showSorterTooltip: false,
      sorter: (a, b) => (a.cliente && b.cliente ? a.cliente.localeCompare(b.cliente) : 0),
      ellipsis: true,
    },
    {
      title: 'Raio',
      dataIndex: 'raioFlange',
      showSorterTooltip: false,
      render: (raioFlange: number) => `${raioFlange}mm`,
      sorter: (a: ConcreteMixerModel, b: ConcreteMixerModel) =>
        (a?.raioFlange ? a.raioFlange : 0) - (b?.raioFlange ? b.raioFlange : 0),
    },
    {
      title: 'Nº de Parafusos',
      dataIndex: 'qtdParafusos',
      showSorterTooltip: false,
      sorter: (a: ConcreteMixerModel, b: ConcreteMixerModel) =>
        (a?.qtdParafusos ? a.qtdParafusos : 0) - (b?.qtdParafusos ? b.qtdParafusos : 0),
    },
    {
      title: 'Conectado',
      dataIndex: 'connectionState',
      showSorterTooltip: false,
      width: '8%',
      render: (connectionState) => {
        return connectionState === 'Connected' ? (
          <Tag style={{ padding: '0.375rem 1rem', color: '#083F18', borderRadius: ' 0.375rem' }} color="#E9F4EE">
            Sim
          </Tag>
        ) : (
          <Tag style={{ padding: '0.375rem 1rem', color: '#620E12', borderRadius: ' 0.375rem' }} color="#FEE9EA">
            Não
          </Tag>
        );
      },
    },
    {
      title: 'STCA',
      dataIndex: 'sensorTemperaturaConcreto',
      showSorterTooltip: false,
      width: '8%',
      render: (stca) => {
        return stca === 1 ? (
          <Tag style={{ padding: '0.375rem 1rem', color: '#083F18', borderRadius: ' 0.375rem' }} color="#E9F4EE">
            Conectado
          </Tag>
        ) : (
          <Tag style={{ padding: '0.375rem 1rem', color: '#620E12', borderRadius: ' 0.375rem' }} color="#FEE9EA">
            Desconectado
          </Tag>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      showSorterTooltip: false,
      sorter: (a: ConcreteMixerModel, b: ConcreteMixerModel) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
      defaultSortOrder: 'descend',
      sortDirections: [],
      render: (id: number, row) => {
        const canEdit = hasAccessByPermission('mixer', 'U');
        const canDelete = hasAccessByPermission('mixer', 'D');
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {(canEdit || canDelete) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ color: '#545454' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    {canEdit && (
                      <MenuItem>
                        <Button type="text" onClick={() => handleEditClick(row, id)}>
                          Editar
                        </Button>
                      </MenuItem>
                    )}
                    {canDelete && (
                      <MenuItem>
                        <Button
                          type="text"
                          onClick={() => {
                            setIsModalVisible(true);
                            setIdConcreteMixer(id);
                          }}
                        >
                          Deletar
                        </Button>
                      </MenuItem>
                    )}
                    {row.connectionState === 'Connected' && (
                      <MenuItem
                        onClick={() => {
                          handleSendCommandClick(row.deviceId || '');
                        }}
                      >
                        <Button type="text">Enviar comando</Button>
                      </MenuItem>
                    )}
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  const handleClick = () => {
    navigate('/gestao/betoneiras/cadastrar');
  };

  const handleEditClick = (mixer: ConcreteMixerModel, id: number) => {
    setConcreteMixer(mixer);
    sessionStorage.setItem('id', id.toString());
    navigate(`/gestao/betoneiras/${mixer.id}`);
  };

  const handleDeleteClick = (e: React.MouseEvent, id: number) => {
    e.preventDefault();
    setIsLoading(true);
    setIsModalVisible(false);
    concreteMixerService
      .delete(`deletar/${id}`)
      .then(() => {
        setIsLoading(false);
        notificationController.success({ message: 'Betoneira deletada com sucesso!' });
        setMixers(mixers?.filter((mixer) => mixer.id != id));
        setMixersFiltered(mixersFiltered?.filter((mixer) => mixer.id != id));
      })
      .catch((err: Error) => {
        setIsLoading(false);
        console.error(err.message);
        notificationController.error({ message: 'Erro ao deletar a betoneira, verificar se está vincluda.' });
      });
  };

  const handleSendCommandClick = (deviceId: string) => {
    setSelectedDeviceId(deviceId);
    setModalSendCommandVisible(true);
  };

  const handleMixerFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setMixersFiltered(
        mixers?.filter(
          (item) =>
            item.numeroBT?.toLowerCase().includes(inputValue) ||
            item.placaCaminhao?.toLowerCase().includes(inputValue) ||
            item.cliente?.toLowerCase().includes(inputValue) ||
            item.modeloCaminhao?.toLowerCase().includes(inputValue),
        ),
      );
    } else {
      setMixersFiltered(mixers);
    }
  };

  const handleExportToExcel = () => {
    genericExportToExcel(
      'Betoneiras',
      columns,
      mixersFiltered?.map((c) => ({
        ...c,
        connectionState: c.connectionState ? 'Sim' : 'Não',
      })) ?? [],
    );
  };

  const fetch = useCallback(() => {
    setIsLoading(true);
    concreteMixerService
      .getArray(`obter`)
      .then((res: ConcreteMixerModel[]) => {
        if (isMounted.current) {
          setMixers(res);
          setMixersFiltered(res);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        notificationController.error({ message: 'Erro ao obter a betoneira.' });
      });
  }, [isMounted]);

  useEffect(() => {
    fetch();

    const filter = readUserFilter();

    if (filter.client?.id) {
      setConcreteMixer((prevState) => ({ ...prevState, idCliente: Number(filter.client!.id) }));
      setClient(filter.client);
    }

    dispatch(
      setHeader({
        title: 'Betoneiras',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  useEffect(() => {
    if (!concreteMixer.idCliente) {
      const filter = readUserFilter();

      if (filter.client?.id) {
        setConcreteMixer((prevState) => ({ ...prevState, idCliente: Number(filter.client!.id) }));
        setClient(filter.client);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        title="Deletar betoneira"
        visible={isModalVisible}
        onOk={(e: React.MouseEvent) => handleDeleteClick(e, idConcreteMixer)}
        onCancel={() => setIsModalVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <CloseCircleOutlined size={20} style={{ color: '#C41B24' }} />
          </Col>
          <Col span={22}>Tem certeza que deseja deletar a betoneira selecionada?</Col>
        </Row>
      </Modal>
      <SendCommandModal
        setVisible={setModalSendCommandVisible}
        visible={modalSendCommandVisible}
        deviceId={selectedDeviceId}
      />
      <PageTitle>Betoneiras</PageTitle>
      <Spinner spinning={isLoading}>
        <Dashboard
          title={`Betoneiras   ${client?.razaoSocial ? `- ${client?.razaoSocial}` : ''}`}
          buttonText={hasAccessByPermission('mixer', 'C') ? 'Cadastrar nova betoneira' : ''}
          handleButtonClick={handleClick}
          table={
            <Table columns={columns} dataSource={mixersFiltered} loading={isLoading} scroll={{ x: 800 }} bordered />
          }
          placeholderTextSearch="Pesquisar por Nº Frota, Placa ou Modelo"
          handleSearchOnChange={handleMixerFilter}
          exportToExcel={handleExportToExcel}
        />
      </Spinner>
    </>
  );
};

export default ConcreteMixerDashboard;
