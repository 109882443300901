import { Button } from '@app/components/common/buttons/Button/Button';
import { Drawer } from '@app/components/common/Drawer/Drawer';
import { useCallback, useEffect, useState } from 'react';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { CardProfile } from '@app/components/common/Card/CardProfile/CardProfile';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { ClientBranchModel } from '@app/domain/interfaces/client_branch/clientBranchModel';
import { ClientService } from '@app/services/clientService';
import { notificationController } from '@app/controllers/notificationController';
import IClientBranchService, { ClientBranchService } from '@app/services/clientBranchService';
import { GroupModel } from '@app/domain/interfaces/client_branch/groupModel';
import IGroupService, { GroupService } from '@app/services/groupService';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setDrawerVisible } from '@app/store/slices/headerSlice';
import IUserService, { UserService } from '@app/services/userService';
import { UserModel } from '@app/domain/interfaces/user/userModel';
import {
  deleteToken,
  deleteUserFilter,
  getClaimValue,
  hasFilter,
  isUserMaster,
  readUserFilter,
  setUserFilter,
} from '@app/services/localStorage.service';
import { Modal } from '@app/components/common/Modal/Modal';
import { InputPassword } from '@app/components/common/inputs/InputPassword/InputPassword.styles';
import { doLogin } from '@app/store/slices/authSlice';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { UserType } from '@app/constants/enums/userType';
import { IUserFilterSessionModel } from '@app/constants/interfaces/Session/IUserFilterSessionModel';
import IUserBranchService, { UserBranchService } from '@app/services/userBranchService';
import { ArgsProps } from 'antd/lib/notification';

const clientService: ClientService = new ClientService();
const clientBranchService: IClientBranchService = new ClientBranchService();
const groupService: IGroupService = new GroupService();
const userService: IUserService = new UserService();
const userBranchService: IUserBranchService = new UserBranchService();

const DrawerChangeVision = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { drawerVisible } = useAppSelector((state) => state.header);

  const [filter, setFilter] = useState({} as IUserFilterSessionModel);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [groups, setGroups] = useState<GroupModel[]>([]);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [branches, setBranches] = useState<ClientBranchModel[]>([]);

  const [inputPassword, setInputPassword] = useState<string>('');
  const [toClone, setToClone] = useState<boolean>(false);
  const [modalPasswordVisible, setModalPasswordVisible] = useState<boolean>(false);

  const closeDrawer = () => dispatch(setDrawerVisible(false));
  const handleUserSiluationChange = (id: number) => setFilter({ ...filter, idUserSimulation: id });
  const handleGroupChange = (id: number) => setFilter({ ...filter, group: groups.find((c) => c.id == id) });
  const handleClientChange = (id: number) => setFilter({ ...filter, client: clients.find((c) => c.id == id) });
  const handleBranchChange = (id: number) => setFilter({ ...filter, branch: branches.find((c) => c.id == id) });
  const handleCleanFilter = () => {
    switch (user?.type) {
      case UserType.Master:
        deleteUserFilter();
        setFilter({});
        break;
      case UserType.ClientAdmin:
        setUserFilter({ client: filter.client });
        setFilter({ client: filter.client });
        break;
    }

    window.location.reload();
  };
  const handleModalPassword = (toClone: boolean) => {
    closeDrawer();
    setModalPasswordVisible(true);
    setToClone(toClone);
  };
  const handleCloneProfile = async () => {
    try {
      setPageLoading(true);

      if (!user?.username) throw { message: 'Erro ao obter o usuário logado' };

      if (toClone) {
        const userSelected = users.find((u) => u.id == filter.idUserSimulation);
        await userService.api.post(`clonarPerfil/${userSelected?.userId}`);
      } else {
        await userService.api.post(`desclonarPerfil/${getClaimValue('userIdSimulation')}`);
      }

      deleteToken();

      await dispatch(
        doLogin({
          username: user?.username,
          password: inputPassword,
        }),
      );

      setUserFilter(filter);

      setPageLoading(false);
      window.location.reload();
    } catch (error) {
      setPageLoading(false);
      notificationController.error(error as ArgsProps);
    }
  };
  const handleSaveChanges = () => {
    try {
      setLoading(true);

      if (isUserMaster() && filter.idUserSimulation) {
        handleModalPassword(true);
        closeDrawer();
      } else {
        setUserFilter(filter);
        closeDrawer();
        window.location.reload();
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchClients = useCallback(() => {
    setLoading(true);

    clientService
      .getArray('obter')
      .then((res: ClientModel[]) => {
        setClients(res);
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
        setLoading(false);
      });
  }, []);
  const fetchCombosData = useCallback(async (idClient: number) => {
    try {
      setLoading(true);

      if (isUserMaster()) {
        const resUsers = await userService.getArray(`obterPorIdCliente/${idClient}`);
        setUsers(resUsers);
      }

      const resGroups = await groupService.getArray(idClient.toString());
      const resBranches = await clientBranchService.getArray(idClient.toString());

      setGroups(resGroups);
      setBranches(resBranches);

      setLoading(false);
    } catch (error) {
      notificationController.error(error as ArgsProps);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filter.client?.id) {
      fetchCombosData(filter.client?.id);
      setFilter((prevState) => ({ ...prevState, group: undefined, branch: undefined, idUserSimulation: undefined }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.client]);

  useEffect(() => {
    if (filter.group?.id) {
      setUsersByGroup(filter.group.id);
      setFilter((prevState) => ({ ...prevState, branch: undefined, idUserSimulation: undefined }));
    }
  }, [filter.group]);

  const setUsersByGroup = async (groupId: number) => {
    setLoading(true);
    const resUsers = await userBranchService.getArray(`obterUsuariosPorAgrupamento/${groupId}`);
    setUsers(resUsers);
    setLoading(false);
  };

  useEffect(() => {
    if (filter.branch?.id) {
      setUsersByBranch(filter.branch.id);
      setFilter((prevState) => ({ ...prevState, idUserSimulation: undefined }));
    }
  }, [filter.branch]);

  const setUsersByBranch = async (branchId: number) => {
    setLoading(true);
    const resUsers = await userBranchService.getArray(`obterUsuariosPorFilial/${branchId}`);
    setUsers(resUsers);
    setLoading(false);
  };

  useEffect(() => {
    if (drawerVisible) {
      setFilter(readUserFilter());
    }
  }, [drawerVisible]);

  return (
    <>
      <Spinner spinning={pageLoading}>
        <Modal
          title="Mudar visão"
          visible={modalPasswordVisible}
          onOk={handleCloneProfile}
          okButtonProps={{ disabled: !inputPassword }}
          onCancel={() => setModalPasswordVisible(false)}
          cancelText="Cancelar"
          okText="Confirmar"
        >
          <p>Digite sua senha para confirmar sua mudança de visão</p>
          <InputPassword
            type="password"
            placeholder="Digite sua senha"
            value={inputPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputPassword(e.currentTarget.value)}
          />
        </Modal>
        <Drawer
          title="Modo de visualização"
          visible={drawerVisible}
          placement="right"
          footer={
            <>
              <div>
                <Button type="ghost" onClick={closeDrawer}>
                  Cancelar
                </Button>
              </div>
              <div>
                {!user?.simulationMode ? (
                  <>
                    {isUserMaster() ? (
                      <Button type="primary" onClick={handleSaveChanges} disabled={!filter.client?.id}>
                        Salvar Troca
                      </Button>
                    ) : (
                      <Button type="primary" onClick={handleSaveChanges} disabled={!filter.branch?.id}>
                        Filtrar
                      </Button>
                    )}
                  </>
                ) : (
                  <Button type="primary" onClick={() => handleModalPassword(false)}>
                    Reverter
                  </Button>
                )}
              </div>
            </>
          }
          textSpan={
            user !== null && user.simulationMode
              ? 'Clique no botão Reverter para retornar à visão original'
              : 'Preencha os campos para mudar a visão do perfil'
          }
          onClose={closeDrawer}
          style={{ overflowX: 'hidden', overflowY: 'hidden' }}
          cardProfile={
            <div style={{ display: 'flex', width: '50%', marginLeft: '4rem', marginBottom: '0.8rem' }}>
              <CardProfile nameUser={`${user?.username}`} email={`${user?.email}`} />
            </div>
          }
        >
          <SpinnerSlump spinning={loading}>
            {user !== null && !user.simulationMode ? (
              <div>
                <BaseFormInputItem label="Concreteira">
                  <Select
                    showArrow
                    showSearch
                    style={{ width: 330 }}
                    placeholder="Selecione a concreteira"
                    disabled={!isUserMaster()}
                    value={filter.client?.id}
                    onChange={(value) => handleClientChange(value as number)}
                    options={clients.map((c) => ({ value: c.id, label: c.razaoSocial }))}
                    filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  />
                </BaseFormInputItem>
                <BaseFormInputItem label="Grupo">
                  <Select
                    showArrow
                    showSearch
                    disabled={filter.client?.id ? false : true}
                    style={{ width: 330 }}
                    placeholder="Selecione os grupos"
                    value={filter.group?.id}
                    options={groups.map((cb) => ({ value: cb.id, label: cb.nome }))}
                    onChange={(value) => handleGroupChange(value as number)}
                    filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  />
                </BaseFormInputItem>

                <BaseFormInputItem label="Filial">
                  <Select
                    showArrow
                    showSearch
                    disabled={filter.client?.id ? false : true}
                    style={{ width: 330 }}
                    value={filter.branch?.id}
                    placeholder="Selecione as filiais"
                    options={
                      filter.group?.id
                        ? branches
                            .filter((cb) => cb.agrupamentos.find((g) => g.idAgrupamento == filter.group?.id))
                            .map((cb) => ({ value: cb.id, label: cb.nome }))
                        : branches.map((cb) => ({ value: cb.id, label: cb.nome }))
                    }
                    onChange={(value) => handleBranchChange(value as number)}
                    filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  />
                </BaseFormInputItem>

                {isUserMaster() && (
                  <BaseFormInputItem label="Usuário">
                    <Select
                      showArrow
                      showSearch
                      style={{ width: 330 }}
                      disabled={filter.client ? false : true}
                      placeholder="Selecione o usuário"
                      value={filter.idUserSimulation}
                      onChange={(value) => handleUserSiluationChange(value as number)}
                      options={users.map((p) => ({ value: p.id, label: p.username }))}
                      filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    />
                  </BaseFormInputItem>
                )}

                {hasFilter() && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bottom: 0,
                      marginTop: '2rem',
                    }}
                  >
                    <Button type="link" onClick={handleCleanFilter}>
                      Limpar Filtros
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
          </SpinnerSlump>
        </Drawer>
      </Spinner>
    </>
  );
};

export default DrawerChangeVision;
