/* eslint-disable react-hooks/exhaustive-deps */
import { CloseCircleOutlined, UserOutlined } from '@ant-design/icons';
import { IconDown, IconMore, IconRight } from '@app/assets/slump-icons';
import { Drawer } from '@app/components/common/Drawer/Drawer';
import { DrawerSpan } from '@app/components/common/Drawer/Drawer.styles';
import { DrawerLinkItem } from '@app/components/common/Drawer/DrawerLink/DrawerLinkItem/DrawerLinkItem';
import { DrawerSuccess } from '@app/components/common/Drawer/DrawerSuccess/DrawerSuccess';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Modal } from '@app/components/common/Modal/Modal';
import { Table } from '@app/components/common/Table/Table';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import Dashboard from '@app/components/dashboard/Dashboard';
import DropdownTable from '@app/components/dropdown/DropdownTable';
import { InsideTable } from '@app/components/tables/InsideTable/InsideTable.styles';
import { hasAccessByPermission } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { ClientBranchModel } from '@app/domain/interfaces/client_branch/clientBranchModel';
import { GroupModel } from '@app/domain/interfaces/client_branch/groupModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import IClientBranchService, { ClientBranchService } from '@app/services/clientBranchService';
import IGroupService, { GroupService } from '@app/services/groupService';
import { readUserFilter } from '@app/services/localStorage.service';
import { setHeader } from '@app/store/slices/headerSlice';
import { Col, Row, Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { genericExportToExcel } from '@app/utils/exportToExcel';

const groupService: IGroupService = new GroupService();
const branchService: IClientBranchService = new ClientBranchService();
const styleGroupParent: React.CSSProperties = {
  fontFamily: 'Mulish',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#383838',
};

const ClientGroupDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isMounted } = useMounted();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [drawerLoading, setDrawerLoading] = useState(false);
  const [drawerGroupOpen, setDrawerGroupOpen] = useState(false);
  const [drawerGroupSuccessOpen, setDrawerGroupSuccessOpen] = useState(false);
  const [modalDeleteGroupVisible, setModalDeleteGroupVisible] = useState(false);
  const [group, setGroup] = useState<GroupModel>({} as GroupModel);
  const [groups, setGroups] = useState<GroupModel[]>();
  const [groupsFiltered, setGroupsFiltered] = useState<GroupModel[]>();
  const [branches, setBranches] = useState<ClientBranchModel[]>();
  const [client, setClient] = useState<ClientModel>({} as ClientModel);

  const handlerEditGroupClick = (group: GroupModel) => {
    navigate(`/gestao/grupos/editar/${group.id}`);
    setDrawerGroupOpen(true);
    setGroup(group);
  };
  const handlerDeleteGroupClick = (group: GroupModel) => {
    setGroup(group);
    setModalDeleteGroupVisible(true);
  };
  const handlerChangeGroup = (e: React.FormEvent<HTMLInputElement>) => {
    setGroup({ ...group, [e.currentTarget.name]: e.currentTarget.value });
  };
  const handlerDeleteGroup = () => {
    setModalLoading(true);
    groupService
      .delete(group.id.toString())
      .then(() => {
        fetchData();
        setModalLoading(false);
        setModalDeleteGroupVisible(false);
        notificationController.success({ message: 'Grupo excluído com sucesso' });
      })
      .catch((error) => {
        notificationController.error(error);
        setModalLoading(false);
      });
  };
  const handlerSaveGroup = async () => {
    setDrawerLoading(true);

    if (group.id) {
      await groupService
        .update('', group)
        .then(() => {
          setDrawerLoading(false);
          setDrawerGroupSuccessOpen(true);
          setDrawerGroupOpen(false);
          setTimeout(() => {
            setDrawerGroupSuccessOpen(false);
            fetchData();
          }, 1000);
        })
        .catch((error) => {
          notificationController.error(error);
          setDrawerLoading(false);
        });
    } else {
      await groupService
        .post('', { ...group, idCliente: client?.id ?? 0 })
        .then(() => {
          setDrawerLoading(false);
          setDrawerGroupSuccessOpen(true);
          setDrawerGroupOpen(false);
          setTimeout(() => {
            setDrawerGroupSuccessOpen(false);
            fetchData();
          }, 1000);
        })
        .catch((error) => {
          notificationController.error(error);
          setDrawerLoading(false);
        });
    }
  };
  const handleGroupFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setGroupsFiltered(
        groups?.filter(
          (item) =>
            item.nome?.toLowerCase().includes(inputValue) || item.id?.toString().toLowerCase().includes(inputValue),
        ),
      );
    } else {
      setGroupsFiltered(groups);
    }
  };

  const columns = (): ColumnsType<GroupModel> => [
    {
      title: 'Nome',
      dataIndex: 'nome',
      showSorterTooltip: false,
      render: (nome) => ({
        props: {
          style: styleGroupParent,
        },
        children: nome,
      }),
    },
    {
      title: '',
      dataIndex: 'id',
      showSorterTooltip: false,
      sorter: (a, b) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
      defaultSortOrder: 'descend',
      render: (_, row) => {
        const canEdit = hasAccessByPermission('group', 'U');
        const canDelete = hasAccessByPermission('group', 'D');
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {(canEdit || canDelete) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ color: '#545454' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    {canEdit && (
                      <MenuItem>
                        <Button type="text" onClick={() => handlerEditGroupClick(row)}>
                          Editar
                        </Button>
                      </MenuItem>
                    )}
                    {canDelete && (
                      <MenuItem>
                        <Button type="text" onClick={() => handlerDeleteGroupClick(row)}>
                          Deletar
                        </Button>
                      </MenuItem>
                    )}
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];
  const expandableGroup: ExpandableConfig<GroupModel> = {
    expandIcon: ({ expanded, onExpand, record }) => {
      const hasBranches = branches?.filter(
        (branch) => branch.agrupamentos?.filter((item) => item.idAgrupamento === record.id).length > 0,
      ).length;

      return (
        <Col
          span={12}
          onClick={(e) => onExpand(record, e)}
          style={{
            display: 'flex',
            justifyContent: 'start',
            cursor: 'pointer',
            alignItems: 'center',
            ...styleGroupParent,
          }}
        >
          <div
            style={{
              width: '64px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {hasBranches ? expanded ? <IconDown /> : <IconRight /> : ''}
          </div>
        </Col>
      );
    },
    expandedRowRender: (record) => {
      return (
        <InsideTable>
          <thead>
            <tr>
              <th>Cód.</th>
              <th>Filial</th>
              <th>Endereço</th>
            </tr>
          </thead>
          <tbody>
            {branches
              ?.filter((branch) => branch.agrupamentos?.filter((item) => item.idAgrupamento === record.id).length > 0)
              .map((branch) => {
                return (
                  <tr key={branch.id}>
                    <td>{branch.id}</td>
                    <td>{branch.nome}</td>
                    <td>
                      <span>
                        {`
                        ${branch.logradouro ? `${branch.logradouro},` : ''}
                        ${branch.numero ? `${branch.numero},` : ''}
                        ${branch.complemento ? `${branch.complemento},` : ''}
                        ${branch.bairro ? `${branch.bairro},` : ''}
                        ${branch.cidade ? `${branch.cidade}-` : ''}
                        ${branch.estado ? `${branch.estado}.` : ''}
                        ${branch.cep ? `${branch.cep}` : ''}
                      `}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </InsideTable>
      );
    },
  };

  const handleExportToExcel = () => {
    genericExportToExcel('Grupos', columns(), groupsFiltered ?? []);
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const resGroups = await groupService.getArray('');

    setGroups(resGroups);
    setGroupsFiltered(resGroups);
    setBranches(await branchService.getArray(''));

    setIsLoading(false);
  }, [isMounted]);

  useEffect(() => {
    const filter = readUserFilter();

    if (filter.client?.id) {
      fetchData();
      setClient(filter.client);
    }
  }, []);
  useEffect(() => {
    if (!drawerGroupOpen) {
      setGroup({} as GroupModel);
    }
  }, [drawerGroupOpen]);
  useEffect(() => {
    if (!modalDeleteGroupVisible) {
      setGroup({} as GroupModel);
    }
  }, [modalDeleteGroupVisible]);

  useEffect(() => {
    if (isMounted.current) {
      dispatch(
        setHeader({
          title: 'Grupos de Filial',
        }),
      );
    }
  }, [isMounted]);

  return (
    <>
      <PageTitle>Grupos</PageTitle>
      <Dashboard
        title={`Grupos ${client?.razaoSocial ? `- ${client?.razaoSocial}` : ''}`}
        buttonText="Cadastrar novo grupo"
        // handleButtonClick={() => setDrawerGroupOpen(true)}
        handleButtonClick={() => navigate('/gestao/grupos/cadastrar')}
        table={
          <Table
            bordered={true}
            columns={columns()}
            dataSource={groupsFiltered}
            loading={isLoading}
            expandable={expandableGroup}
            rowKey="id"
          />
        }
        placeholderTextSearch="Pesquisar por nome do grupo"
        handleSearchOnChange={handleGroupFilter}
        exportToExcel={handleExportToExcel}
      />

      <Modal
        title="Deletar grupo"
        open={modalDeleteGroupVisible}
        onOk={handlerDeleteGroup}
        onCancel={() => setModalDeleteGroupVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <CloseCircleOutlined size={20} style={{ color: '#C41B24' }} />
          </Col>
          <Col span={22}>Tem certeza que deseja excluir este grupo?</Col>
        </Row>
        <Spin spinning={modalLoading}></Spin>
      </Modal>
      <Drawer
        title={group?.id ? 'Editar Grupo' : 'Criar Grupo'}
        placement="right"
        onClose={() => setDrawerGroupOpen(false)}
        open={drawerGroupOpen}
        footer={
          <Row gutter={6} style={{ width: '100%' }}>
            <Col span={12}>
              <Button block type="ghost" onClick={() => setDrawerGroupOpen(false)}>
                Cancelar
              </Button>
            </Col>
            <Col span={12}>
              <Button
                block
                type="primary"
                disabled={group?.nome && client?.id ? false : true}
                onClick={handlerSaveGroup}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        }
        textSpan="Preencha os campos abaixo para cadastrar uma nova filial para o cliente"
        style={{ overflowX: 'hidden' }}
      >
        <Spin spinning={drawerLoading}>
          <BaseForm layout="vertical" style={{ maxWidth: '350px' }}>
            {client.id ? (
              <BaseFormInputItem label="Concreteira">
                <DrawerLinkItem
                  style={{ marginTop: '-2px', width: '100%', maxWidth: '400px' }}
                  title={client.razaoSocial}
                  subtitle={client.cnpj}
                  icon={<UserOutlined />}
                ></DrawerLinkItem>
              </BaseFormInputItem>
            ) : (
              <DrawerSpan style={{ margin: '2rem 0rem 2rem 0rem', color: 'var(--error-color)' }}>
                Necessário selecionar um cliente no filtro para finalizar o cadastro
              </DrawerSpan>
            )}
            <BaseFormInputItem
              label="Nome grupo"
              supportText="Como o grupo vai ficar conhecido no sistema"
              //isSuccess={group.nome ? undefined : false}
              //errorText="Nome inválido"
            >
              <Input
                name="nome"
                placeholder="Informe o Nome do grupo"
                value={group.nome}
                onChange={handlerChangeGroup}
              />
            </BaseFormInputItem>
          </BaseForm>
        </Spin>
      </Drawer>
      <DrawerSuccess
        text="Grupo salvo com sucesso!"
        title="Grupo"
        placement="right"
        onClose={() => setDrawerGroupSuccessOpen(false)}
        open={drawerGroupSuccessOpen}
        style={{ overflowX: 'hidden' }}
      />
    </>
  );
};

export default ClientGroupDashboard;
