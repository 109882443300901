import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';
import { BranchTrackingModel } from '@app/domain/interfaces/tracking/branchTrackingModel';
import { GroupTrackingModel } from '@app/domain/interfaces/tracking/groupTrackingModel';
import { PrepareAction, createAction, createSlice } from '@reduxjs/toolkit';

export interface ITrackingSlice {
  branchTrackingSelected: BranchTrackingModel;
  branchsTracking: BranchTrackingModel[];
  groupTrackingSelected: GroupTrackingModel;
  groupsTracking: GroupTrackingModel[];
  mixer?: ConcreteMixerModel;
}

const initialState: ITrackingSlice = {
  branchTrackingSelected: {
    id: '',
    idCliente: '',
    nome: '',
    agrupamentos: [],
    codigoFilialSiac: 0,
    codigoPontoDeCarga01: 0,
    codigoPontoDeCarga02: 0,
    codigoPontoDeCarga03: 0,
  },
  branchsTracking: [],
  groupTrackingSelected: {
    id: '',
    idCliente: '',
    nome: '',
  },
  groupsTracking: [],
};

export const setHeaderBranchsTracking = createAction<PrepareAction<BranchTrackingModel[]>>(
  'tracking/setHeaderBranchsTracking',
  (branchs: BranchTrackingModel[]) => {
    return {
      payload: branchs,
    };
  },
);

export const setHeaderBranchSelected = createAction<PrepareAction<BranchTrackingModel>>(
  'tracking/setHeaderBranchSelected',
  (branch: BranchTrackingModel) => {
    return {
      payload: branch,
    };
  },
);

export const setHeaderGroupSelected = createAction<PrepareAction<GroupTrackingModel>>(
  'tracking/setHeaderGroupSelected',
  (group: GroupTrackingModel) => {
    return {
      payload: group,
    };
  },
);

export const setHeaderGroupsTracking = createAction<PrepareAction<GroupTrackingModel[]>>(
  'tracking/setHeaderGroupsTracking',
  (groups: GroupTrackingModel[]) => {
    return {
      payload: groups,
    };
  },
);

export const trackingSlice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setHeaderBranchsTracking, (state, action) => {
      state.branchsTracking = action.payload;
    });

    builder.addCase(setHeaderBranchSelected, (state, action) => {
      state.branchTrackingSelected = action.payload;
    });

    builder.addCase(setHeaderGroupSelected, (state, action) => {
      state.groupTrackingSelected = action.payload;
    });

    builder.addCase(setHeaderGroupsTracking, (state, action) => {
      state.groupsTracking = action.payload;
    });
  },
});

export default trackingSlice.reducer;
